import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import Icon from "../icon";
import translations from "./translations.json";

interface TooltipsProps {
  plane: boolean;
  hotel: boolean;
  ticket: boolean;
  bus?: boolean;
  boat?: boolean;
  train?: boolean;
  tent?: boolean;
}

const Tooltips: React.FC<TooltipsProps> = ({ plane, hotel, ticket, bus, boat, train, tent }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <div className="tooltips">
      {plane && (
        <div className="tooltips__tooltip">
          <Icon name="plane" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "FLIGHT_INCLUSIVE")}</span>
          </div>
        </div>
      )}
      {bus && (
        <div className="tooltips__tooltip">
          <Icon name="bus" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "BUS_INCLUSIVE")}</span>
          </div>
        </div>
      )}
      {boat && (
        <div className="tooltips__tooltip">
          <Icon name="boat" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "BOAT_INCLUSIVE")}</span>
          </div>
        </div>
      )}
      {train && (
        <div className="tooltips__tooltip">
          <Icon name="train" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "TRAIN_INCLUSIVE")}</span>
          </div>
        </div>
      )}
      {hotel && (
        <div className="tooltips__tooltip">
          <Icon name="bed-booking" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "ACCOMMODATION_INCLUSIVE")}</span>
          </div>
        </div>
      )}
      {tent && (
        <div className="tooltips__tooltip">
          <Icon name="tent" className="icon icon-tent" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "TENT_INCLUSIVE")}</span>
          </div>
        </div>
      )}
      {ticket && (
        <div className="tooltips__tooltip">
          <Icon name="ticket" />
          <div className="tooltips__label">
            <span className="tooltips__label-text">{translate(translations, language, "TICKET_INCLUSIVE")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltips;
