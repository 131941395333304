import { ServiceType } from "@qite/tide-client";
import { BookingPackageItem } from "@qite/tide-client/build/types";
import { first, isEmpty, orderBy } from "lodash";
import React, { createContext, useEffect, useState } from "react";
import { getTourcodes, searchPackages } from "../../services/product-service";

interface PackageProviderProps {
  allotmentTourCode?: string;
  eventIds?: string[];
  teamEventIds?: string[];
  otherTournamentTagId?: number;
  allotmentTagIds?: number[];
  countryId?: number;
  flightsIncluded?: boolean;
  allGames?: boolean;
  eventPage?: boolean;
}

export interface PackageContextType {
  packageDetail?: BookingPackageItem;
  packages?: BookingPackageItem[];
  packagesLoading: boolean;
  // otherTransportPackage?: BookingPackageItem;
  // otherTransportPackageLoading?: boolean;
  teamPackages: BookingPackageItem[];
  teamPackagesLoading: boolean;
  otherTournamentPackages: BookingPackageItem[];
  otherTournamentPackagesLoading: boolean;
  otherTournamentTagId?: number;
  setOtherTournamentTagId: (tagId?: number) => void;
}

const initialState: PackageContextType = {
  packageDetail: undefined,
  packages: [],
  packagesLoading: true,
  // otherTransportPackage: undefined,
  // otherTransportPackageLoading: false,
  teamPackages: [],
  teamPackagesLoading: true,
  otherTournamentPackages: [],
  otherTournamentPackagesLoading: true,
  otherTournamentTagId: undefined,
  setOtherTournamentTagId: () => {},
};

const PackageContext = createContext<PackageContextType>(initialState);

export const PackageProvider: React.FC<PackageProviderProps> = ({
  allotmentTourCode,
  eventIds,
  teamEventIds,
  allotmentTagIds,
  countryId,
  flightsIncluded,
  allGames,
  eventPage,
  children,
}) => {
  const [packageDetail, setPackageDetail] = useState<BookingPackageItem>();
  const [packages, setPackages] = useState<BookingPackageItem[]>([]);
  const [packagesLoading, setPackagesLoading] = useState<boolean>(true);
  // const [otherTransportPackage, setOtherTransportPackage] = useState<BookingPackageItem>();
  // const [otherTransportPackageLoading, setOtherTransportPackageLoading] = useState<boolean>(false);
  const [teamPackages, setTeamPackages] = useState<BookingPackageItem[]>([]);
  const [teamPackagesLoading, setTeamPackagesLoading] = useState<boolean>(true);
  const [otherTournamentPackages, setOtherTournamentPackages] = useState<BookingPackageItem[]>([]);
  const [otherTournamentPackagesLoading, setOtherTournamentPackagesLoading] = useState<boolean>(true);
  const [otherTournamentTagId, setOtherTournamentTagId] = useState<number>();

  const getPackages = async (
    eventIds: string[],
    allotmentTagIds: number[],
    countryId?: number,
    flightsIncluded?: boolean,
    onlyFirst?: boolean,
    signal?: AbortSignal
  ) => {
    const packages = await searchPackages(eventIds ?? [], allotmentTagIds, countryId, flightsIncluded, signal);
    if (packages && packages.length) {
      if (onlyFirst) {
        return packages.slice(0, 1);
      }
      return packages;
    }
    return [] as BookingPackageItem[];
  };

  // const getPackageDetails = async (packageItem: BookingPackageItem, signal?: AbortSignal) =>
  //   await searchPackageDetail(packageItem, false, undefined, undefined, undefined, signal);

  // Details for a specific allotment and events
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (eventIds && !isEmpty(eventIds)) {
        setPackages([]);
        setPackagesLoading(true);
        try {
          // fetch tourcodes
          const tourcodes = (await getTourcodes(eventIds, signal)).tourCodes;
          const results = !isEmpty(tourcodes)
            ? orderBy(
                (await getPackages(tourcodes, [], undefined, true, false, signal)).flatMap((d) => d).filter((d) => d.catalogueId),
                (nd) => nd.fromDate
              )
            : [];
          setPackageDetail(first(results));
          setPackages(results);
        } catch (error) {
          console.log(error);
        } finally {
          setPackagesLoading(false);
        }
      }
    })();
    return () => {
      if (!isEmpty(eventIds)) controller.abort();
    };
  }, [allotmentTourCode, eventIds, flightsIncluded]);

  // Search Details for events of the same club
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      setTeamPackages([]);
      if (teamEventIds && !isEmpty(teamEventIds)) {
        setTeamPackagesLoading(true);
        try {
          const tourcodes = (await getTourcodes(teamEventIds, signal)).tourCodes;
          const packages = !isEmpty(tourcodes)
            ? orderBy(
                (await getPackages(tourcodes, [], undefined, true, false, signal)).filter((d) => d.catalogueId),
                (nd) => nd.fromDate
              )
            : [];
          setTeamPackages(packages.slice(0, 8));
        } catch (error) {
          console.log(error);
        } finally {
          setTeamPackagesLoading(false);
        }
      }
    })();
    return () => {
      if (!isEmpty(teamEventIds)) controller.abort();
    };
  }, [teamEventIds]);

  // Search Details for events of the Tournament
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (allotmentTagIds && !isEmpty(allotmentTagIds)) {
        setPackages([]);
        setPackagesLoading(true);
        try {
          const results = await getPackages([], allotmentTagIds, undefined, true, false, signal);
          setPackages(orderBy(results, (d) => d.fromDate));
        } catch (error) {
          console.log(error);
        } finally {
          setPackagesLoading(false);
        }
      }
    })();
    return () => {
      if (!isEmpty(allotmentTagIds)) controller.abort();
    };
  }, [allotmentTagIds]);

  // Search Details for events of the Tournament
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (otherTournamentTagId) {
        setOtherTournamentPackages([]);
        setOtherTournamentPackagesLoading(true);
        try {
          const results = await getPackages([], [otherTournamentTagId], undefined, true, false, signal);
          setOtherTournamentPackages(
            orderBy(
              results.filter((d) => !eventIds?.some((e) => d?.allotment.tourCode.startsWith(e))),
              "fromDate"
            )
          );
        } catch (error) {
          console.log(error);
        } finally {
          setOtherTournamentPackagesLoading(false);
        }
      }
    })();
    return () => {
      if (!isEmpty([otherTournamentTagId])) controller.abort();
    };
  }, [otherTournamentTagId]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (countryId) {
        setPackages([]);
        setPackagesLoading(true);
        try {
          const results = await getPackages([], [], countryId, true, false, signal);
          setPackages(orderBy(results, (d) => d.fromDate));
        } catch (error) {
          console.log(error);
        } finally {
          setPackagesLoading(false);
        }
      }
    })();
    return () => {
      if (countryId) controller.abort();
    };
  }, [countryId]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (allGames) {
        setPackages([]);
        setPackagesLoading(true);
        try {
          const results = await getPackages([], [], undefined, true, false, signal);
          setPackages(orderBy(results, (d) => d.fromDate));
        } catch (error) {
          console.log(error);
        } finally {
          setPackagesLoading(false);
        }
      }
    })();
    return () => {
      if (allGames) controller.abort();
    };
  }, [allGames]);

  return (
    <PackageContext.Provider
      value={{
        packageDetail,
        packages,
        packagesLoading,
        teamPackages,
        teamPackagesLoading,
        otherTournamentPackages,
        otherTournamentPackagesLoading,
        otherTournamentTagId,
        setOtherTournamentTagId,
      }}
    >
      {children}
    </PackageContext.Provider>
  );
};

export default PackageContext;
